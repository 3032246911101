import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const AboutUsStoryBlocks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { de: { template: { eq: "about-us-story-block" } } }
        }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                bulletPoints {
                  text
                }
              }
              en {
                title
                description
                bulletPoints {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const storyBlocks = markdownUnwrapper(data, language);

  return (
    <div className="container" id="story">
      <div className="about-inner-area">
        <div className="row">
          {storyBlocks.map(({ title, description, bulletPoints }, idx) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={idx}>
              <div className="about-text">
                <h3>{title}</h3>
                <p>
                  <DangerousMarkdownContent content={description} />
                </p>
                <ul className="features-list">
                  {(bulletPoints || []).map(({ text }, subIdx) => (
                    <li key={subIdx}>
                      <i className="flaticon-tick"></i>
                      <DangerousMarkdownContent content={text} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUsStoryBlocks;

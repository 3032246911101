import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import PageBanner from "../components/Common/PageBanner";
import AboutUsContent from "../timewise-custom/components/AboutUs/AboutUsContent";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";

const AboutUs = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t("about:title")} />
      <Navbar />
      <PageBanner
        pageTitle={t("about:title")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("about:breadcrumb")}
      />
      <AboutUsContent />
    </Layout>
  );
};

export default AboutUs;

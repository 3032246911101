import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import AboutUsStoryBlocks from "../AboutUs/AboutUsStoryBlocks";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { unwrapNode } from "../../../utils/markdown-unwrapper";
import owlIcon from "../../../assets/images/owl.png";
import shape1 from "../../../assets/images/shape/circle-shape1.png";

const AboutUsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      foundedImage: file(relativePath: { eq: "about-us-founded.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamImage: file(relativePath: { eq: "about-us-team.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutUs: markdownRemark(
        frontmatter: { de: { template: { eq: "about-us" } } }
      ) {
        frontmatter {
          de {
            description
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          en {
            description
            title
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { title, description, image } = unwrapNode(data.aboutUs, language);

  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image d-flex justify-content-center align-items-center">
              <Img
                alt={t("about:title")}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: 600,
                  borderRadius: 20,
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
                fluid={image.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={owlIcon} alt={t("about:title")} width={30} />
                  {t("about:title")}
                </span>
                <h2>{title}</h2>

                <ul className="features-list">
                  <li
                    className="d-flex flex-column flex-md-row align-items-center p-3"
                    style={{ minHeight: 150 }}
                  >
                    <div className="position-relative">
                      <Img
                        style={{
                          height: 100,
                          width: 100,
                          maxWidth: 100,
                        }}
                        fluid={data.foundedImage.childImageSharp.fluid}
                      />
                    </div>
                    <div className="flex-grow-1 text-center">
                      <h3>2020</h3>
                      <p>{t("about:founded")}</p>
                    </div>
                  </li>
                  <li
                    className="d-flex flex-column flex-md-row align-items-center p-3"
                    style={{ minHeight: 150 }}
                  >
                    <div className="position-relative">
                      <Img
                        style={{
                          height: 80,
                          width: 80,
                          maxWidth: 80,
                        }}
                        fluid={data.teamImage.childImageSharp.fluid}
                      />
                    </div>
                    <div className="flex-grow-1 text-center">
                      <h3>4</h3>
                      <p>{t("about:team-members")}</p>
                    </div>
                  </li>
                </ul>
                <p>
                  <DangerousMarkdownContent content={description} />
                </p>

                <Link to="/team" className="default-btn">
                  <i className="flaticon-right"></i>
                  {t("about:to-the-team")}
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutUsStoryBlocks />
      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  );
};

export default AboutUsContent;
